
.dropdown {
    margin: 0;
    padding: 0;
    border: none;
    cursor: pointer;
    text-align: left !important;
}

.dropdown > button {
    color: #4466f2;
    text-decoration: none;
    display: block;
    padding: 18px 15px;
    cursor: pointer;
    border: 0px;
    background-color: transparent;
}
.dropdown > button.longtitle {
    padding: 8px 15px;
    
}
.dropdown-menu li {
    border: none !important;
}
/* Hide default scrollbar */
::-webkit-scrollbar {
    width: 10px; 
    
  }
  
  /* Track (background) of the scrollbar */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; /* Set your preferred color for the track */
  }
  
  /* Handle (thumb) of the scrollbar */
  ::-webkit-scrollbar-thumb {
    background: #4e70ea; 
    border-radius: 4px;
  }
  
.overFlow{
    overflow-y: auto !important;
    height: 300px !important;;
}

.smallFlow{
    height: 100%;
}

.dropdown-item {
    color: #000000 !important;
    font-size: 14px;
    font-weight: 400;
    padding: 3px 10px;
    display: block;
    white-space: nowrap;
    transition: all 0.3s ease-in-out;
}
.dropdown-item:hover {
    background-color: #ffffff !important;
    color: #000000 !important;
    font-size: 15px;
    font-weight: 700;
    margin-left: 5px;
}
.dropdown-container{
    position: static;
    padding: 5px 10px;
    display: flex;
    flex-direction: column;
    height: auto;
    border: 1px solid rgb(58, 123, 221);
}
.dropdown-container.long{
    height: 410px;
    overflow-y: scroll;
}
.dropdown-container li {
    margin: 1px 0px !important;
}

.children {
    display: none;
    position: absolute;
    left: 100%;
    
}
.dropdown-item-hover:hover > .children.active{
    display: block;
}
.children.active {
    display: none;
    position: absolute;
    left: 100%;
    visibility: visible;
    opacity: 1;
    z-index: 1;
    cursor: pointer;
    left: 138px;
}
/* .dropdownHoverBtn{
    margin-top: 5px;
} */
.dropdown-menu{
    position: absolute;
    margin-top:0;
    padding-top: 0px;
    transform: translate3d(0px, 55px, 0px);
    top: 0px;
    left: 0;
    will-change: transform;
}

/* .dropdown:hover > .dropdown-menu{
    display: block !important;
} */

.dropdown-menu.show{
    display: block;
    height: auto !important;
    /* overflow-y: auto; */
}
.subDropdown{
    margin-left: 11px;
    padding: 5px 10px;
    border: 1px solid gray;
    display: flex;
    background: #fff;
    flex-direction: column;
    position: relative;
    top: -35px !important;
}
.subDropdown > li a {
    color: #000000 !important;
    font-size: 12px;
    font-weight: 400;
    padding: 3px 10px;
    display: block;
    white-space: nowrap;
    transition: all 0.3s ease-in-out;
}
.subDropdown > li:hover > a {
    background-color: #ffffff !important;
    font-size: 14px;
    font-weight: 500;
}

.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}