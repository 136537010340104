/* .accordion_item.active .button {
  background-color: #105057;
} */
.answer_wrapper {
  height: 0;
  overflow: hidden;
  transition: height ease 0.2s;

}
.answer_wrapper.open {
  height: auto;
  padding: 20px 0px;
  border: 1px solid gray;
  /* height: 100px; */
}

.moduleInternalLinks{
  margin-left: 25px;;
}

/* .accordion_item.active .button {
  background-color: #105057;
} */
.accordionX {
  height: 0;
  overflow: hidden;
}
.accordionX.open {
  height: auto;
}