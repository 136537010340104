img.img-responsive.challanImg{
    width: 16%;
    height: 16%;
}
.company-section{
    display: flex;
    padding : 15px 0px 0px 40px;
    align-items : center;
}
.text_company{
    font-size: 18px;
    margin-bottom: -10px;
    
}
.Digital_text{
    font-weight: bold;
    color: black;
    margin-left: 10px;
}
.company_italic_text{
    letter-spacing: -0.3px;
    border-bottom: 1px solid black;
}
.company_address{
    font-size:12px;
}
.h1_challan{
    font-size: 2.25rem;
    padding-top: 25px;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
}
/* @media print {
  #challan_Page {
    size: 8.77in 5.5in;
  }
} */
/* @media print {
    @page {
        size: 8.77in 5.5in; 
    }
  } */
  @media print {
    .print_button {
      display: none; /* Hide the print buttons during print preview */
    }
  }
  @media print {
    #heading-th {
      background: #4a4a4a !important;
      color: white !important;
    }
  
    
  }