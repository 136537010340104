.table th, .table td {
    padding: 2px 5px !important;

    // vertical-align: middle;
    // border-top: 1px solid #dee2e6;
}
.report-header {
    .company-info {
        text-align: right;
        padding: 50px 20px;
        display: flex;
        justify-content: left;

        .company-name {
            h1 {
                text-transform: uppercase;
                border-bottom: 2px solid black;
            }
            .company-moto {
                font-size: 12px;
            }
        }
        img {
            height: auto;
            width: 200px;
        }

    }
    .report-for {
        width: 30%;
        margin: 0 auto;
        text-align: center;

        h2:nth-child(1) {
            font-weight: 400;
            border: 2px solid black;
        }
        h2:nth-child(2) {
            width: 30%;
            margin: 0 auto;
            font-weight: 400;
            border: 2px solid black;
        }
    }
}

.report-footer {

    .put-signature {
        height: 50px;
    }
    .footer-signature {
        // text-decoration: overline;
        border-top: 2px solid black;
    }
}