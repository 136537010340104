.user_card {
    height: 300px;
    width: 350px;
    margin-top: 10%;
    margin-bottom: auto;
    background: rgb(237, 238, 243);
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 5px;

}

.brand_logo_container {
    position: absolute;
    height: 150px;
    width: 150px;
    top: -75px;
    border-radius: 50%;
    background: #ffffff;
    padding: 10px;
    text-align: center;
    border: 8px solid gray;
}
.brand_logo_container_design {
    height: 130px;
    width: 130px;
    border-radius: 50%;
    border: 2px solid gray;
    margin-left: -7px;
    margin-top: -7px;
}
.brand_logo {
    /* height: 80px; */
    width: 79%;
    /* border-radius: 90%; */
    margin-top: 35px;
}
@media screen and (max-width: 450px) {
    #login-container {
        top: 110px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }
}
@media screen and (min-width: 451px) and (max-width: 767px) {
    #login-container {
        top: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }
}
@media screen and (min-width: 768px) and (max-width: 1100px) {
    #login-container {
        top: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }
}






/* .brand_logo {
    height: 148px;
    width: 148px;
    border-radius: 50%;
    border: 2px solid gray;
    margin-left: -6px;
    margin-top: -6px;
} */
.form_container {
    margin-top: 100px;
}
.login_btn {
    width: 100%;
    background: #787878 !important;
    color: white !important;
}
.login_btn:focus {
    box-shadow: none !important;
    outline: 0px !important;
}
.login_container {
    padding: 0 2rem;
}
.input-group-text {
    background: #787878 !important;
    color: white !important;
    border: 0 !important;
    border-radius: 0.25rem 0 0 0.25rem !important;
}
.input_user,
.input_pass:focus {
    box-shadow: none !important;
    outline: 0px !important;
}
.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
    background-color: #c0392b !important;
}