#page-main-header{
    color: #3f3f3f;
    position: relative;
    margin-bottom: 0;
    min-height: 66px;
    padding-right: 0;
    padding-left: 20px;
    border-radius: 0;
    box-shadow: none;
    border: none;
    background: url(../../../../assets/images/4.png);
    border-bottom: 1px solid #c4c4c4;
}